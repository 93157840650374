export const menus = [
    {
        path: '/benchmarks',
        label: 'Benchmarks'
    },
    {
        path: '/devices',
        label: 'Devices'
    },
    {
        path: '/results',
        label: 'Results'
    },

]

export const isMobile = () => {
    return window.innerWidth < 700
}

export const emailValidator = (email) => {
    return  /^\w+([\\.-]?\w+)*@\w+([\\.-]?\w+)*(\.\w{2,3})+$/.test(email)
}

export const passwordValidator = (password) => {
    return /^(?=.*[A-Z])(?=.*[0-9])(?=.*[a-z]).{8,}$/.test(password)
}

export const resultColorCodes = {
    good: {
        color: '#0b7533',
        gcolor: '#053316',
        bColor: '#11b14d',
    },
    avg: {
        color: '#ffb705',
        gcolor: '#947b3e',
        bColor: 'yellow'
    },
    poor: {
        color: '#a44a16',
        gcolor: '#46200a',
        bColor: '#f26d21'
    },
}


