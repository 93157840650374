import axios from 'axios'
import router from './router'

const _baseUrl = process.env.VUE_APP_SERVER_URL
const UN_AUTH_STATUS_CODE = 403;

const urlGenerator = async (path, pathParams, queryParams) => {
    let url = path;
    url = pathParams.length ? `${url}/${pathParams.join('/')}` : url;
    url = Object.keys(queryParams).length ? `${url}?${getQString(pathParams)}` : url;
    return url
}

const getQString = obj => {
    const ec = v=>encodeURIComponent(v);
    return Object.keys(obj).map((key) => {
        return `${ec(key)}=${ec(obj[key])}`;
    }).join("&");
}

const getTokenHeader = () => {
    return { 'Authorization' : localStorage.getItem('token') }
}

const getPostRequestHeaders = () => {
    return { 'Content-Type' : 'application/json'}
}

export const get = async (path, authRequired, pathParams = [], queryParams = {}) => {
    const url = await urlGenerator(path, pathParams, queryParams)
    let headers = authRequired ? {...getTokenHeader()} : {}
    return await _sendRequest(url, 'get', {}, headers, [200])
}

export const post = async (path, authRequired, data, pathParams = [], queryParams = {}) => {
    const url = await urlGenerator(path, pathParams, queryParams)
    let headers = authRequired ? {...getPostRequestHeaders(), ...getTokenHeader()} : getPostRequestHeaders()
    return await _sendRequest(url, 'post', data, headers, [201, 200])
}

export const put = async (path, authRequired, data, pathParams = [], queryParams = {}) => {
    const url = await urlGenerator(path, pathParams, queryParams)
    let headers = authRequired ? {...getPostRequestHeaders(), ...getTokenHeader()} : getPostRequestHeaders()
    return await _sendRequest(url, 'put', data, headers, [200])
}

export const remove = async (path, authRequired, data, pathParams = [], queryParams = {}) => {
    const url = await urlGenerator(path, pathParams, queryParams)
    let headers = authRequired ? {...getTokenHeader()} : {}
    return await _sendRequest(url, 'delete', data, headers, [200])
}



const _sendRequest = async (url, method, data, headers, expectedStatus = []) => {

    try {
        const res = await axios.request({
            'url': url,
            'method': method,
            'baseURL': _baseUrl,
            'data': data,
            'headers' : headers,
        })
        if(expectedStatus.length && expectedStatus.indexOf(res.status) === -1){
            throw new Error(`Response with unexpected status code ${res.status}`)
        }
        return res.data
    } catch(err) {
        if(err.response && UN_AUTH_STATUS_CODE == err.response.status) {
            console.error(`Unauthorized to access request ${url}`)
            _redirectToLogin();
        }
        throw err;
    }
}

const _redirectToLogin = ()=> {
    localStorage.clear()
    router.push({path: '/'})
}







