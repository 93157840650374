<template >
    <div class="flex w-100 col">
        <div class="flex image-uploader">
            <van-uploader :preview-full-image="false" v-model="file" :after-read="afterRead" :before-read="beforeRead" multiple :max-count="1" :max-size="maxSize" @oversize="onOversize" @delete="onDelete"/>
        </div>
        <div v-if="error.visible" class="error-name pt-5 pb-5 pl-15 pr-15">{{error.text}}</div>
    </div>
</template>
<script setup>
import {ref, defineEmits, defineProps, inject, watch, reactive} from 'vue'
const imageServerUrl = inject('imageServerUrl')
const emit = defineEmits(['image-loaded', 'image-deleted'])
const maxSize = process.env.VUE_APP_MAX_IMAGE_SIZE * 1024
const file = ref([])
const supportedImageTypes = ['jpg', 'jpeg', 'png']
const props = defineProps(['image'])

const imageObj = {
    imageData: '',
    imageName: '',
    imageSize: 0,
    imageType: ''
}

const error = reactive({
    text: '',
    visible: false
})

const beforeRead = (file) => {
    if(!supportedImageTypes.includes(file.type.split('/')[1])) {
        error.visible = true
        error.text = "Please upload an image in 'jpg', 'jpeg' or 'png' format"
        return false
    }
    error.visible = false
    return true
}

const afterRead = async (file) => {
    imageObj.imageType = file.file.type.split('/')[1]
    imageObj.imageData = file.content
    imageObj.imageName = file.file.name
    imageObj.imageSize = file.file.size
    imageObj.file = file.file
    emit('image-loaded', imageObj)
};
const onOversize = () => {
    error.visible = true
    error.text= `Image size cannot exceed ${process.env.VUE_APP_MAX_IMAGE_SIZE} kb`
};

const onDelete = () => {
    emit('image-deleted')
}

watch(()=> props.image, async (imagePath) => {
    file.value = []
    if(imagePath){
        file.value.push({url: imageServerUrl+imagePath})
    }
})
</script>
<style lang="scss">

</style>